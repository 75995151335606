import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactFlagsSelect from 'react-flags-select';
import { useGetCompanyQuery, useUpdateCompanyMutation } from 'api/companies';
import { useDraftMutation, useGetDraftQuery } from 'api/users';
import { processPortalFormSuccess } from 'utils/helper/FormHandlers';
import { generateBusinessDetailsBody, generateProfileDraftBody } from 'utils/helper/RequestBody';
import { businessDetailsErrorBody } from 'utils/helper/ResponseConverter';
import {
  handleServerValidationErrors,
  requiredField,
  validatePortalDocuments,
  validateOptionalPortalDocuments,
  validateEmail,
  validateOptionalEmail,
} from 'utils/helper/Validations';
import { labelWithHints } from 'utils/helper/LabelWithHints';
import { mergeFileResponce, mergeResponce, toSnakecaseKeys } from 'utils/helper/ObjectKeyConverter';
import { errorToastConfig } from 'utils/constants/ToastConfig';
import {
  BUSINESS_CONTACTS_FORM_KEY,
  BUSINESS_DETAILS_COMPANY_EMAIL_CHECKBOX,
  BUSINESS_DETAILS_LEGAL_DOMICILE_CHECKBOX,
  TERMS_AND_CONDITIONS_CHECKBOX,
} from 'utils/constants/portals';
import Button from 'components/BaseComponents/Button';
import Input from 'components/BaseComponents/Input';
import Tooltip from 'components/BaseComponents/Tooltip';
import Icon from 'components/BaseComponents/Icon';
import CheckBox from 'components/BaseComponents/CheckBox';
import Radio from 'components/BaseComponents/Radio';
import ChooseFile from 'components/Shared/ChooseFile';
import Toastify from 'components/Shared/ToastNotification/Toastify';
import { hasSubscriptionMemberRole } from 'utils/helper/UserRoleValidation';
import './style.scss';

const BusinessDetailsForm = ({ companyId, isEditable, onTabChange, toastRef, userDetails }) => {
  const draftName = "BusinessDetailsForm"
  const { t } = useTranslation();
  const { data: businessDetails } = useGetCompanyQuery({ id: companyId });
  const { data: businessDetailsDraft = {} } = useGetDraftQuery({ id: userDetails?.id, draftName });
  const initialValues = mergeFileResponce(mergeResponce(businessDetails, businessDetailsDraft.payload), businessDetailsDraft.attachments);

  const [updateCompany] = useUpdateCompanyMutation();
  const [updateDraft] = useDraftMutation();
  const isSubscriptionMember = hasSubscriptionMemberRole(userDetails);
  const isOptional = isSubscriptionMember;

  const handleBusinessDetailsUpdate = async (payload) => {
    const requestBody = await generateBusinessDetailsBody(payload);
    return updateCompany({ companyId, payload: requestBody })
      .unwrap()
      .then(() => {
        onTabChange(BUSINESS_CONTACTS_FORM_KEY);
        processPortalFormSuccess(toastRef);
      })
      .catch(({ data: { message }, status }) => {
        const errorMessage = businessDetailsErrorBody(toSnakecaseKeys(message));
        return handleServerValidationErrors(toastRef, status, errorMessage);
      });
  };

  const saveAsDraft = (values) => {
    const payload = generateProfileDraftBody(values, draftName);

    updateDraft({ id: userDetails.id, payload })
      .unwrap()
      .then(() => {
        onTabChange(BUSINESS_CONTACTS_FORM_KEY);
        processPortalFormSuccess(toastRef);
      })
      .catch(({ data: { errors }, status }) =>
        handleServerValidationErrors(toastRef, status, errors),
      );
  }

  const copyPersonalEmail = (form) => {
    const {
      values: { is_using_personal_email },
    } = form.getState();

    if (!is_using_personal_email) {
      return;
    }

    form.change('email', userDetails.email);
  };

  const copyDomicileAddress = (form) => {
    const {
      values: {
        domicile_address_attributes_line_1,
        domicile_address_attributes_line_2,
        domicile_address_attributes_city,
        domicile_address_attributes_zip_code,
        domicile_address_attributes_country_code,
        is_using_legal_domicile_address,
      },
    } = form.getState();

    if (!is_using_legal_domicile_address) {
      return;
    }

    form.batch(() => {
      form.change('address_attributes_line_1', domicile_address_attributes_line_1);
      form.change('address_attributes_line_2', domicile_address_attributes_line_2);
      form.change('address_attributes_city', domicile_address_attributes_city);
      form.change('address_attributes_zip_code', domicile_address_attributes_zip_code);
      form.change('address_attributes_country_code', domicile_address_attributes_country_code);
    });
  };

  const uncheckCompanyEmailCheckbox = (form) => {
    form.change(BUSINESS_DETAILS_COMPANY_EMAIL_CHECKBOX, false);
  };

  const uncheckCompanyAddressChecbox = (form) => {
    form.change(BUSINESS_DETAILS_LEGAL_DOMICILE_CHECKBOX, false);
  };

  const totalUploadedFiles = (form) => {
    const {
      values: { registration_certificate, incorporation_certificate, company_customs_certificate },
    } = form.getState();

    let totalFiles = 0;
    if (registration_certificate) {
      totalFiles += 1;
    }
    if (incorporation_certificate) {
      totalFiles += 1;
    }
    if (company_customs_certificate) {
      totalFiles += 1;
    }
    return totalFiles;
  };

  const sectionTooltipHeading = (label, tooltip) => (
    <div className="flex items-center gap-4px business-details__tooltip-section margin-t-40px">
      <span className="business-details__tooltip-section--header">{label}</span>
      {tooltip && (
        <Tooltip
          className={null}
          content={tooltip}
          position="top"
          showArrow
          size="small"
          type="midnight-blue"
        >
          <Icon name="help" color="default" />
        </Tooltip>
      )}
      {!isOptional && <span className="business-details__tooltip-section--required">*</span>}
    </div>
  );

  const requiredSectionHeading = (label, required) => (
    <h5 className="business-details__section-header">
      {label}
      {required && <span className="business-details__section-header--required">*</span>}
    </h5>
  );

  return (
    <div className="business-details">
      <h3 className="business-details__title">{t('profile.business_details.title')}</h3>
      <Form
        onSubmit={(values) => handleBusinessDetailsUpdate(values)}
        initialValues={initialValues}
        render={({ form, handleSubmit, submitting, hasValidationErrors, values }) => (
          <form
            onSubmit={(event) => {
              if (hasValidationErrors) {
                toastRef?.current?.notify(<Toastify type="error" />, errorToastConfig);
              }
              handleSubmit(event);
            }}
            className="padding-x-24px padding-y-32px"
          >
            <fieldset disabled={!isEditable}>
              {requiredSectionHeading(t('profile.business_details.basic_details'), !isOptional)}
              <div className="grid">
                <Field name="name" validate={isOptional ? null : requiredField}>
                  {({ input, meta }) => (
                    <Input
                      label={labelWithHints(
                        t('profile.business_details.company_name.label'),
                        t('profile.business_details.company_name.tooltip'),
                      )}
                      placeholder={t('profile.business_details.company_name.placeholder')}
                      required={!isOptional}
                      className="col-6 col-bleed padding-r-10px margin-t-16px"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      onChange={input.onChange}
                      disabled={!isEditable}
                    />
                  )}
                </Field>
                <Field name="country_code_of_registration" validate={isOptional ? null : requiredField}>
                  {({ input, meta }) => (
                    <div className="col-6 col-bleed padding-l-10px margin-t-16px">
                      <p className="business-details__country--title">
                        {t('profile.business_details.company_country.label')}
                        {!isOptional && <span className="business-details__country--title--required">*</span>}
                      </p>
                      <ReactFlagsSelect
                        selectButtonClassName={classNames('business-details__country--dropdown', {
                          'business-details__country--dropdown--error':
                            meta?.touched && meta?.error,
                        })}
                        searchable={true}
                        placeholder={t('profile.business_details.company_country.placeholder')}
                        selected={input.value}
                        onSelect={(code) => input.onChange(code)}
                        disabled={!isEditable}
                        className={classNames('react-flag-dropdown', {
                          'react-flag-dropdown--disabled': !isEditable,
                        })}
                      />
                    </div>
                  )}
                </Field>
              </div>
              <div className="grid">
                <Field name="registration_number" validate={isOptional ? null : requiredField}>
                  {({ input, meta }) => (
                    <Input
                      label={labelWithHints(
                        t('profile.business_details.company_registration.label'),
                        t('profile.business_details.company_registration.tooltip'),
                      )}
                      placeholder={t('profile.business_details.company_registration.placeholder')}
                      required={!isOptional}
                      className="col-6 col-bleed padding-r-10px margin-t-20px"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      onChange={input.onChange}
                      disabled={!isEditable}
                    />
                  )}
                </Field>
                <Field name="custom_code">
                  {({ input, meta }) => (
                    <Input
                      label={labelWithHints(
                        t('profile.business_details.company_custom_code.label'),
                        t('profile.business_details.company_custom_code.tooltip'),
                      )}
                      placeholder={t('profile.business_details.company_custom_code.placeholder')}
                      size="small"
                      className="col-6 col-bleed padding-l-10px margin-t-20px"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      onChange={input.onChange}
                      disabled={!isEditable}
                    />
                  )}
                </Field>
              </div>
              {sectionTooltipHeading(
                t('profile.business_details.legal_domicile.label'),
                t('profile.business_details.legal_domicile.tooltip'),
              )}
              <Field name="domicile_address_attributes_line_1" validate={isOptional ? null : requiredField}>
                {({ input, meta }) => (
                  <Input
                    label={t('profile.business_details.company_address.address1.label')}
                    placeholder={t('profile.business_details.company_address.address1.placeholder')}
                    required={!isOptional}
                    size="small"
                    className="margin-t-20px"
                    error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                    touched={meta.touched}
                    value={input.value}
                    onChange={(event) => {
                      input.onChange(event);
                      uncheckCompanyAddressChecbox(form);
                    }}
                    disabled={!isEditable}
                  />
                )}
              </Field>
              <Field name="domicile_address_attributes_line_2">
                {({ input, meta }) => (
                  <Input
                    label={t('profile.business_details.company_address.address2.label')}
                    placeholder={t('profile.business_details.company_address.address2.placeholder')}
                    required={false}
                    size="small"
                    className="margin-t-20px"
                    error={meta?.error || meta?.submitError}
                    touched={meta.touched}
                    value={input.value}
                    onChange={(event) => {
                      input.onChange(event);
                      uncheckCompanyAddressChecbox(form);
                    }}
                    disabled={!isEditable}
                  />
                )}
              </Field>
              <div className="grid">
                <Field name="domicile_address_attributes_city" validate={isOptional ? null : requiredField}>
                  {({ input, meta }) => (
                    <Input
                      label={t('profile.business_details.company_address.city.label')}
                      placeholder={t('profile.business_details.company_address.city.placeholder')}
                      required={!isOptional}
                      className="col-6 col-bleed margin-t-20px padding-r-10px"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      onChange={(event) => {
                        input.onChange(event);
                        uncheckCompanyAddressChecbox(form);
                      }}
                      disabled={!isEditable}
                    />
                  )}
                </Field>
                <Field name="domicile_address_attributes_zip_code" validate={isOptional ? null : requiredField}>
                  {({ input, meta }) => (
                    <Input
                      label={t('profile.business_details.company_address.zip_code.label')}
                      placeholder={t(
                        'profile.business_details.company_address.zip_code.placeholder',
                      )}
                      required={!isOptional}
                      size="small"
                      className="col-6 col-bleed margin-t-20px padding-l-10px"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      onChange={(event) => {
                        input.onChange(event);
                        uncheckCompanyAddressChecbox(form);
                      }}
                      disabled={!isEditable}
                    />
                  )}
                </Field>
              </div>
              <Field name="domicile_address_attributes_country_code" validate={isOptional ? null : requiredField}>
                {({ input, meta }) => (
                  <div className="margin-t-20px">
                    <p className="business-details__country--title">
                      {t('profile.business_details.company_address.country.label')}
                      {!isOptional && <span className="business-details__country--title--required">*</span>}
                    </p>
                    <ReactFlagsSelect
                      selectButtonClassName={classNames('business-details__country--dropdown', {
                        'business-details__country--dropdown--error': meta?.touched && meta?.error,
                      })}
                      searchable
                      placeholder={t(
                        'profile.business_details.company_address.country.placeholder',
                      )}
                      selected={input.value}
                      onSelect={(code) => {
                        input.onChange(code);
                        uncheckCompanyAddressChecbox(form);
                      }}
                      disabled={!isEditable}
                      className={classNames('react-flag-dropdown', {
                        'react-flag-dropdown--disabled': !isEditable,
                      })}
                    />
                  </div>
                )}
              </Field>
              <Field name={BUSINESS_DETAILS_COMPANY_EMAIL_CHECKBOX} type="checkbox">
                {({ input }) => (
                  <CheckBox
                    id="companyEmail"
                    label={t(
                      'profile.business_details.company_address.company_email_checkbox_label',
                    )}
                    className="flex items-center margin-t-16px margin-b-20px business-details__checkbox"
                    size="tiny"
                    isChecked={input.checked}
                    onChange={(event) => {
                      input.onChange(event);
                      copyPersonalEmail(form, event);
                    }}
                    disabled={!isEditable}
                  />
                )}
              </Field>
              <Field name="email" validate={isOptional ? validateOptionalEmail : validateEmail}>
                {({ input, meta }) => (
                  <Input
                    label={t('profile.business_details.company_address.company_email.label')}
                    placeholder={t(
                      'profile.business_details.company_address.company_email.placeholder',
                    )}
                    required={!isOptional}
                    size="small"
                    className="margin-t-20px margin-b-40px"
                    error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                    touched={meta.touched}
                    value={input.value}
                    onChange={(event) => {
                      input.onChange(event);
                      uncheckCompanyEmailCheckbox(form);
                    }}
                    disabled={!isEditable}
                  />
                )}
              </Field>
              {requiredSectionHeading(t('profile.business_details.company_address_title'), !isOptional)}
              <Field name={BUSINESS_DETAILS_LEGAL_DOMICILE_CHECKBOX} type="checkbox">
                {({ input }) => (
                  <CheckBox
                    id="legalDomicileAddress"
                    label={t('profile.business_details.company_address.use_same_address')}
                    className="flex items-center margin-t-16px margin-b-20px business-details__checkbox"
                    size="tiny"
                    isChecked={input.checked}
                    onChange={(event) => {
                      input.onChange(event);
                      copyDomicileAddress(form, event);
                    }}
                    disabled={!isEditable}
                  />
                )}
              </Field>
              <Field name="address_attributes_line_1" validate={isOptional ? null : requiredField}>
                {({ input, meta }) => (
                  <Input
                    label={t('profile.business_details.company_address.address1.label')}
                    placeholder={t('profile.business_details.company_address.address1.placeholder')}
                    required={!isOptional}
                    size="small"
                    className="margin-t-20px"
                    error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                    touched={meta.touched}
                    value={input.value}
                    onChange={(event) => {
                      input.onChange(event);
                      uncheckCompanyAddressChecbox(form);
                    }}
                    disabled={!isEditable}
                  />
                )}
              </Field>
              <Field name="address_attributes_line_2">
                {({ input, meta }) => (
                  <Input
                    label={t('profile.business_details.company_address.address2.label')}
                    placeholder={t('profile.business_details.company_address.address2.placeholder')}
                    required={false}
                    size="small"
                    className="margin-t-20px"
                    error={meta?.error || meta?.submitError}
                    touched={meta.touched}
                    value={input.value}
                    onChange={(event) => {
                      input.onChange(event);
                      uncheckCompanyAddressChecbox(form);
                    }}
                    disabled={!isEditable}
                  />
                )}
              </Field>
              <div className="grid">
                <Field name="address_attributes_city" validate={isOptional ? null : requiredField}>
                  {({ input, meta }) => (
                    <Input
                      label={t('profile.business_details.company_address.city.label')}
                      placeholder={t('profile.business_details.company_address.city.placeholder')}
                      required={!isOptional}
                      className="col-6 col-bleed margin-t-20px padding-r-10px"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      onChange={(event) => {
                        input.onChange(event);
                        uncheckCompanyAddressChecbox(form);
                      }}
                      disabled={!isEditable}
                    />
                  )}
                </Field>
                <Field name="address_attributes_zip_code" validate={isOptional ? null : requiredField}>
                  {({ input, meta }) => (
                    <Input
                      label={t('profile.business_details.company_address.zip_code.label')}
                      placeholder={t(
                        'profile.business_details.company_address.zip_code.placeholder',
                      )}
                      required={!isOptional}
                      size="small"
                      className="col-6 col-bleed margin-t-20px padding-l-10px"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      onChange={(event) => {
                        input.onChange(event);
                        uncheckCompanyAddressChecbox(form);
                      }}
                      disabled={!isEditable}
                    />
                  )}
                </Field>
              </div>
              <Field name="address_attributes_country_code" validate={isOptional ? null : requiredField}>
                {({ input, meta }) => (
                  <div className="margin-t-20px">
                    <p className="business-details__country--title">
                      {t('profile.business_details.company_address.country.label')}
                      {!isOptional && <span className="business-details__country--title--required">*</span>}
                    </p>
                    <ReactFlagsSelect
                      selectButtonClassName={classNames('business-details__country--dropdown', {
                        'business-details__country--dropdown--error': meta?.touched && meta?.error,
                      })}
                      searchable
                      placeholder={t(
                        'profile.business_details.company_address.country.placeholder',
                      )}
                      selected={input.value}
                      onSelect={(code) => {
                        input.onChange(code);
                        uncheckCompanyAddressChecbox(form);
                      }}
                      disabled={!isEditable}
                      className={classNames('react-flag-dropdown', {
                        'react-flag-dropdown--disabled': !isEditable,
                      })}
                    />
                  </div>
                )}
              </Field>

              {sectionTooltipHeading(
                t('profile.business_details.communication_method.header.label'),
                t('profile.business_details.communication_method.header.tooltip'),
              )}
              <div className="flex flex-col gap-8px margin-t-16px">
                <Field type="radio" name="preferred_contact_method" validate={isOptional ? null : requiredField}>
                  {({ meta }) => (
                    <div className="flex items-center gap-8px business-details__delivery-option">
                      {t('profile.business_details.communication_method.question')}
                      {(meta?.error || meta?.submitError) && (
                        <Tooltip
                          content={meta?.error || meta?.submitError}
                          type="danger"
                          position="bottom-right"
                          gap={0}
                        >
                          <Icon name="invalid" color="danger" />
                        </Tooltip>
                      )}
                    </div>
                  )}
                </Field>
                <Field
                  type="radio"
                  value="email"
                  name="preferred_contact_method"
                  validate={isOptional ? null : requiredField}
                >
                  {({ input }) => (
                    <Radio
                      id="emailCommunication"
                      label={t('profile.business_details.communication_method.email')}
                      name={input.name}
                      size="large"
                      isChecked={input.checked}
                      withCheckIcon={input.checked}
                      onChange={() => input.onChange(input.value)}
                      disabled={!isEditable}
                    />
                  )}
                </Field>
                <Field
                  type="radio"
                  value="sms"
                  name="preferred_contact_method"
                  validate={isOptional ? null : requiredField}
                >
                  {({ input }) => (
                    <Radio
                      id="smsCommunication"
                      label={t('profile.business_details.communication_method.sms')}
                      name={input.name}
                      size="large"
                      isChecked={input.checked}
                      withCheckIcon={input.checked}
                      onChange={() => input.onChange(input.value)}
                      disabled
                    />
                  )}
                </Field>
              </div>

              <div className="flex flex-col items-left justify-between business-details__tooltip-section margin-t-40px">
                {requiredSectionHeading(
                  t('profile.business_details.terms_and_conditions_checkbox.label'), true
                )}
                <Field
                  name={TERMS_AND_CONDITIONS_CHECKBOX}
                  type="checkbox"
                  validate={requiredField}
                >
                  {({ input, meta }) => (
                    <CheckBox
                      id="acceptTermsAndConditions"
                      label={
                        <>
                          {t('profile.business_details.terms_and_conditions_checkbox.text')}
                          <span className="business-details__section-header--required">*</span>
                        </>
                      }
                      className="flex items-center margin-t-16px margin-b-20px business-details__checkbox"
                      size="tiny"
                      isChecked={input.checked}
                      required
                      onChange={(event) => {
                        input.onChange(event);
                      }}
                      disabled={!isEditable}
                      hintText={meta.touched && (meta?.error || meta?.submitError)}
                    />
                  )}
                </Field>
              </div>

              <div className="flex items-center justify-between business-details__tooltip-section margin-t-40px">
                <div className="flex items-center gap-4px">
                  <span className="business-details__tooltip-section--header">
                    {labelWithHints(
                      t(t('profile.business_details.documents.header.label')),
                      t('shared.incorrect_document_tooltip'),
                    )}
                  </span>
                  {/* <span className="business-details__tooltip-section--required">*</span> */}
                </div>
                <p className="business-details__tooltip-section--file-count">
                  {totalUploadedFiles(form)}
                  /3
                </p>
              </div>
              <div className="margin-t-22px padding-b-16px business-details__upload">
                <Field name="registration_certificate" validate={isOptional ? validateOptionalPortalDocuments : validatePortalDocuments}>
                  {({ input, meta }) => (
                    <ChooseFile
                      fileName={input.value?.name}
                      fileUrl={businessDetails?.registration_certificate?.url}
                      label={
                        <>
                          {t('profile.business_details.documents.registration_certificate')}
                          {!isOptional && <span className="business-details__tooltip-section--required">*</span>}
                        </>
                      }
                      hint={t('profile.file.upload')}
                      onChange={(file) => {
                        input.onChange(file);
                      }}
                      touched={meta?.touched}
                      errorMessage={meta?.error || meta?.submitError}
                      isEditable={isEditable}
                    />
                  )}
                </Field>
              </div>

              <div className="margin-t-16px padding-b-16px business-details__upload">
                <Field name="incorporation_certificate" validate={validateOptionalPortalDocuments}>
                  {({ input, meta }) => (
                    <ChooseFile
                      fileName={input.value?.name}
                      fileUrl={businessDetails?.incorporation_certificate?.url}
                      label={t('profile.business_details.documents.incorporation_certificate')}
                      hint={t('profile.file.upload')}
                      onChange={(file) => {
                        input.onChange(file);
                      }}
                      touched={meta?.touched}
                      errorMessage={meta?.error || meta?.submitError}
                      isEditable={isEditable}
                    />
                  )}
                </Field>
              </div>

              <div className="margin-t-16px margin-b-20px padding-b-16px business-details__upload">
                <Field
                  name="company_customs_certificate"
                  validate={validateOptionalPortalDocuments}
                >
                  {({ input, meta }) => (
                    <ChooseFile
                      fileName={input.value?.name}
                      fileUrl={businessDetails?.company_customs_certificate?.url}
                      label={t('profile.business_details.documents.company_customs_certificate')}
                      hint={t('profile.file.upload')}
                      touched={meta?.touched}
                      onChange={(file) => {
                        input.onChange(file);
                      }}
                      errorMessage={meta?.error || meta?.submitError}
                      isEditable={isEditable}
                    />
                  )}
                </Field>
              </div>

              {isSubscriptionMember && (
                <div className="flex flex-col items-left justify-between business-details__tooltip-section margin-t-40px">
                  {requiredSectionHeading(t('profile.business_details.subscription_member.label'), true)}
                  <Field name="registration_reason" validate={requiredField}>
                    {({ input, meta }) => (
                      <Input
                        label={t('profile.business_details.subscription_member.text')}
                        placeholder={t('profile.business_details.subscription_member.placeholder')}
                        required
                        size="small"
                        className="margin-t-20px"
                        error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                        touched={meta.touched}
                        value={input.value}
                        onChange={input.onChange}
                        disabled={!isEditable}
                      />
                    )}
                  </Field>
                </div>
              )}

              {isEditable && (
                <>
                  <Button
                    className="business-details__submit-btn margin-t-40px"
                    label={t('profile.submit')}
                    submitType="submit"
                    type="success"
                    disabled={submitting}
                  />
                  <Button
                    className="business-details__draft-btn margin-t-40px margin-l-8px"
                    label="Save as draft"
                    onClick={() => saveAsDraft(values)}
                    type="default"
                    disabled={submitting}
                  />
                </>
              )}
            </fieldset>
          </form>
        )}
      />
    </div>
  );
};

BusinessDetailsForm.defaultProps = {
  isEditable: true,
  toastRef: null,
  onTabChange: () => {},
};

BusinessDetailsForm.propTypes = {
  companyId: PropTypes.number.isRequired,
  isEditable: PropTypes.bool,
  onTabChange: PropTypes.func,
  toastRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  userDetails: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
  }).isRequired,
};

export default BusinessDetailsForm;
